import { faInfoCircle,faTicketAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addToCart, removeFromCart } from '../../../service/actions/cartActions'
import {
  Menu,
  MenuItem,
  MenuButton,
  MenuRadioGroup,MenuDivider
} from '@szhsin/react-menu';
import Popup from "../../../sections/ui/Popup/Popup";
import LabeledInput from '../../../sections/ui/formfields/LabeledInput';
import DateInput from '../../../sections/ui/formfields/LabededDateField';
import CustomSelect from '../../../sections/ui/formfields/CustomSelect'
import moment from 'moment';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import RadioInput2 from '../../../sections/ui/formfields/RadioInput2';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import { useAlert } from 'react-alert';

const TicketCounter1 = props => {
  const { ticket, event, show, show1 } = props
  const { t, i18n } = useTranslation('common');

  //const alert = useAlert();
  const [firstName, setFirstName] = useState([...Array(ticket.persons).fill('')]);
  const [lastName, setLastName] = useState([...Array(ticket.persons).fill('')]);
  const [customerEmail, setCustomerEmail] = useState([...Array(ticket.persons).fill('')]);
  const [customerEmailConfirm, setCustomerEmailConfirm] = useState([...Array(ticket.persons).fill('')]);
  const [phoneCode, setPhoneCode] = useState([...Array(ticket.persons).fill('')]);
  const [clubCode, setClubCode] = useState([...Array(ticket.persons).fill('')]);
  const [birthPlace, setBirthPlace] = useState([...Array(ticket.persons).fill('')]);
  const [birthDate, setBirthDate] = useState([...Array(ticket.persons).fill(new Date())])
  const [passportNo, setPassportNo] = useState([...Array(ticket.persons).fill('')]);
  const [expiration, setExpiration] = useState([...Array(ticket.persons).fill('')]);
  const [nationality, setNationality] = useState([...Array(ticket.persons).fill('')]);
  const [invoiceselect, SetInvoiceSelect] = useState([...Array(ticket.persons).fill({
    label: t('No'),
    value: 0
   })]);
  const [invoiceDetail, setInvoiceDetail] = useState([...Array(ticket.persons).fill('')])
  const [personData,setPersonData] = useState([...Array(ticket.persons).fill('')]);
  const [customerPopup, setCustomerPopup] = useState(false);
  const [data, setData] = useState({});
  const [count, setCount] = useState(0)
  const cartItems = useSelector(state => state.cart.cartItems)
  const dispatch = useDispatch()
  const [supplies, setSupplies] = useState(false)
  const [allow, setAllow] = useState(true)
  const [suppliesdata, setSuppliesData] = useState({})
  const [allsuppliesdata, setAllSuppliesData] = useState({})
  const [existingUser, setExistingUser] = useState({})
  var maxtict = (ticket.ticket_limit <= (ticket.quantity - Number(ticket.booked_qty)) ? props.max : (ticket.quantity - Number(ticket.booked_qty)));//ticket.quantity
  if(ticket.quantity - Number(ticket.booked_qty) <= 0){
    maxtict = 0;
  }
  if(show1 === 0){
    maxtict = ticket.quantity - Number(ticket.booked_qty);
  }
  const HandlerBut = (val)=>{
    ticket.selected_supplement=suppliesdata;
    if (val === 'add' && count < +(maxtict)) {
      if(ticket.supplements.length>0)
     {
       let supplements=[];
       for(var y=0;y<ticket.supplements.length;y++)
       {
        if(ticket.supplements[y].supplement_type!=2)
        {
          
        if(ticket.supplements[y].supplement_applied==1 || (ticket.supplements[y].supplement_applied==2 && count==0))
        {
          if(ticket.supplements[y].supplement_type==1)
          {
         if($("#"+ticket.id+'-counter-popup' +' .check-'+ticket.supplements[y].id).is(':checked')==false && ticket.supplements[y].supplement_required==1)
         {
          alert(ticket.supplements[y].supplement_title+" is Required")
          return
         }
         else
         {
          let res = $("#"+ticket.id+'-counter-popup' +' .check-'+ticket.supplements[y].id).is(':checked')
          supplements.push({title:ticket.supplements[y].supplement_title,id:ticket.supplements[y].id,value:res,type:ticket.supplements[y].supplement_type,price:res?ticket.supplements[y].supplement_price:0,supplement_applied:ticket.supplements[y].supplement_applied})
         }
        }
        else
      {
        if(!$("#"+ticket.id+'-counter-popup' +' .check-'+ticket.supplements[y].id).val() && ticket.supplements[y].supplement_required==1)
        {
         alert(ticket.supplements[y].supplement_title+" is Required")
         return
        }
        else
        {
         let res = $("#"+ticket.id+'-counter-popup' +' .check-'+ticket.supplements[y].id).val()
         supplements.push({title:ticket.supplements[y].supplement_title,id:ticket.supplements[y].id,value:res,type:ticket.supplements[y].supplement_type,price:0,supplement_applied:ticket.supplements[y].supplement_applied})
        }
      }
      }
        }
        else
        {
          if(ticket.supplements[y].supplement_applied==1 || (ticket.supplements[y].supplement_applied==2 && count==0))
          {
          if(ticket.supplements[y].supplement_multiple_type==1)
          {
         if(!$("#"+ticket.id+'-counter-popup' +' .check-'+ticket.supplements[y].id+" input[type=radio]:checked").val() && ticket.supplements[y].supplement_required==1)
         {
         alert(ticket.supplements[y].supplement_title+" is Required") 
         return
         }
         else
         {
          if($("#"+ticket.id+'-counter-popup' +' .check-'+ticket.supplements[y].id+" input[type=radio]:checked").val())
          {supplements.push({title:ticket.supplements[y].supplement_title,id:ticket.supplements[y].id,value:[JSON.parse(ticket.supplements[y].supplement_option)[parseInt($("#"+ticket.id+'-counter-popup' +' .check-'+ticket.supplements[y].id+" input[type=radio]:checked").val())]],type:ticket.supplements[y].supplement_type
          ,price:JSON.parse(ticket.supplements[y].supplement_option)[parseInt($("#"+ticket.id+'-counter-popup' +' .check-'+ticket.supplements[y].id+" input[type=radio]:checked").val())].price,supplement_applied:ticket.supplements[y].supplement_applied,multi:1})
         }
          else
          supplements.push({title:ticket.supplements[y].supplement_title,id:ticket.supplements[y].id,value:[],type:ticket.supplements[y].supplement_type,price:0,supplement_applied:ticket.supplements[y].supplement_applied,multi:1}) 
        }
          }
          else
          {
            var selecetedlist=[];
              $("#"+ticket.id+'-counter-popup' +' .check-'+ticket.supplements[y].id).each(function () {
               if(this.checked)
               selecetedlist.push($(this).val())
              });
            if(selecetedlist.length==0 && ticket.supplements[y].supplement_required==1)
            {
            alert(ticket.supplements[y].supplement_title+" is Required") 
            return
            }
            else
            {
             if(selecetedlist.length!=0)
             {
              let pricetotal=0;
              let options = [];
              for(var newt=0;newt<selecetedlist.length;newt++)
              {
                options.push(JSON.parse(ticket.supplements[y].supplement_option)[selecetedlist[newt]])
                pricetotal+=parseFloat(JSON.parse(ticket.supplements[y].supplement_option)[selecetedlist[newt]].price);
              }
              supplements.push({title:ticket.supplements[y].supplement_title,id:ticket.supplements[y].id,value:options,type:ticket.supplements[y].supplement_type
             ,price:pricetotal,supplement_applied:ticket.supplements[y].supplement_applied,multi:0})
            }
             else
             supplements.push({price:0,title:ticket.supplements[y].supplement_title,id:ticket.supplements[y].id,value:[],type:ticket.supplements[y].supplement_type,supplement_applied:ticket.supplements[y].supplement_applied,multi:0}) 
           }
          }
        }
        }
        }
        let keyuse = (count+1)+"";
        if(ticket.selected_supplement)
        ticket.selected_supplement[keyuse]=supplements;
        else
        {
          ticket.selected_supplement={}
          ticket.selected_supplement[keyuse]=supplements;
        }
        setSupplies(true)
        setSuppliesData(ticket.selected_supplement)
     }
      setPersonDetail()
      setCount(count + 1)
      dispatch(addToCart(event.id, ticket.id, count + 1, event, ticket))
    } else if (val === 'sub' && !(count === 0 || !Number.isInteger(count))) {
      removeLastFromLocalStorage("customer_name",event.id, ticket.id,count);
      removeLastFromLocalStorage("customer_phone",event.id, ticket.id,count);
      removeLastFromLocalStorage("customer_email",event.id, ticket.id,count)
      removeLastFromLocalStorage("customer_club",event.id, ticket.id,count);
      removeLastFromLocalStorage("customer_birthplace",event.id, ticket.id,count);
      removeLastFromLocalStorage("customer_birth",event.id, ticket.id,count);
      removeLastFromLocalStorage("customer_passport",event.id, ticket.id,count);
      removeLastFromLocalStorage("customer_expiration",event.id, ticket.id,count);
      removeLastFromLocalStorage("customer_nationality",event.id, ticket.id,count);
      removeLastFromLocalStorage("customer_invoice",event.id, ticket.id,count);
      removeLastFromLocalStorage("customer_invoicedetail",event.id, ticket.id,count);
      if (count - 1 <= 0) {
        if(ticket.supplements.length>0)
        {
        ticket.selected_supplement={};
        setSuppliesData(ticket.selected_supplement)
        }
        dispatch(removeFromCart(event.id, ticket.id))
      } else {
        if(ticket.supplements.length>0)
        {
          let keyuse = count+"";
          delete ticket.selected_supplement[keyuse];
          setSuppliesData(ticket.selected_supplement)
        }
        dispatch(addToCart(event.id, ticket.id, count - 1, event, ticket))
      }
      setCount(count - 1)
    } else {
      return false
    }
    $(".checkboxmy").prop('checked', false);
    $(".text-input-mine").val('');
    setData({});
  }

  useEffect(() => {
    if (props.value) {
      setCount(props.value)
    }
    cartItems.forEach(item => {
      if(ticket && item.ticket_id === ticket.id && item.event_id === event.id){
        setCount(item.ticket_qty)
        setAllSuppliesData(item.ticket.supplements?item.ticket.supplements:[])
        setSuppliesData(item.ticket.selected_supplement?item.ticket.selected_supplement:{})      
        ticket.selected_supplement=item.ticket.selected_supplement?item.ticket.selected_supplement:{};
      }
    })
    
  }, [props.value])
  const changeHandler = e => {
    e.target.value=count;
  }
  useEffect(()=>{
     let by_default = false;
    if(ticket.supplements.length!=0)
    {
      ticket.supplements.forEach(supplement=>{
        if(supplement.supplement_type!=2 && (supplement.supplement_applied==1 || (supplement.supplement_applied==2 && count==0)))
        {
          by_default = true;
        }
        if( supplement.supplement_type==2 && (supplement.supplement_applied==1 || (supplement.supplement_applied==2 && count==0))) 
        {
          by_default = true
        }
      })
     setAllow(by_default)
    }
   
},[count])

const clickHandler = val => {
  if (val === 'add' && count < +(maxtict)) {
  setFirstName([...Array(ticket.persons).fill('')])
  setLastName([...Array(ticket.persons).fill('')])
  setCustomerEmail([...Array(ticket.persons).fill('')])
  setCustomerEmailConfirm([...Array(ticket.persons).fill('')])
  setPhoneCode([...Array(ticket.persons).fill('')])
  setClubCode([...Array(ticket.persons).fill('')])
  setBirthPlace([...Array(ticket.persons).fill('')])
  setPassportNo([...Array(ticket.persons).fill('')])
  setExpiration([...Array(ticket.persons).fill('')])
  setNationality([...Array(ticket.persons).fill('')])
  setBirthDate([...Array(ticket.persons).fill(new Date())])
  SetInvoiceSelect([...Array(ticket.persons).fill({
    label: t('No'),
    value: 0
   })])
  setInvoiceDetail([...Array(ticket.persons).fill('')])
  setPersonData([...Array(ticket.persons).fill('')]);
  setData(val);
  setExistingUser(getCustomerNames())
  setCustomerPopup(true);
   }
   else
   {
   HandlerBut(val);
   }
  }
  const getCustomerNames = ()=>{
    if(localStorage.getItem("customer_name"))
    {
     let all_customers = JSON.parse(localStorage.getItem("customer_name"));
     let customer_names = "";
     Object.keys(all_customers).forEach((key,index) => { 
      if(index == 0) customer_names = all_customers[key];
      else customer_names+=` , ${all_customers[key]}`
    })
    return customer_names;
    }
    else
    return "";
  }
  
  const removeLastFromLocalStorage = (key,eventid,ticketid,count)=> {
    const existing = localStorage.getItem(key);
    if (existing) {
        let items = JSON.parse(existing);
        delete items[`ticket_${eventid}_${ticketid}_${count}`]
        if (Object.keys(items).length > 0) {
            localStorage.setItem(key, JSON.stringify(items));
        } else {
            localStorage.removeItem(key); 
        }
    }
  }
  const updateLocalStorage = (key, value,eventid,ticketid,count) =>{
    const existing = localStorage.getItem(key);
    let items = existing ? JSON.parse(existing) : {};
    items[`ticket_${eventid}_${ticketid}_${count}`]=value;
    localStorage.setItem(key, JSON.stringify(items));
  }

  const allRequiredFilled = ()=>{
    for(var i=0; i<firstName.length;i++)
    {
    if(firstName[i] && lastName[i] && phoneCode[i] && clubCode[i] && birthPlace[i]&& passportNo[i] && expiration[i] && nationality[i] && birthDate[i] && customerEmail[i]  && (invoiceselect[i].value==0 || (invoiceselect[i].value==1 && invoiceDetail[i])) && (customerEmail[i]==customerEmailConfirm[i])) {}
    else return false;
    }
    return true;
  }

  const setPersonDetail = ()=>
    {
      let pNames = [];
      let pbirthDates = [];
      let pinvoiceSelect = [];
      let pinvoiceDetails = [];
      for(var i=0; i<firstName.length;i++)
      {
       pNames.push(`${firstName[i]}_${lastName[i]}`);
       pbirthDates.push(moment(birthDate[i]).format('YYYY-MM-DD'))
       pinvoiceSelect.push((invoiceselect[i].value==1?"Yes":"No"))
       pinvoiceDetails.push((invoiceselect[i].value==1?invoiceDetail[i]:""))
      }
      updateLocalStorage("customer_name",pNames.join(' , '),event.id, ticket.id,count+1);
      updateLocalStorage("customer_phone",phoneCode.join(' , '),event.id, ticket.id,count+1);
      updateLocalStorage("customer_email",customerEmail.join(' , '),event.id, ticket.id,count+1)
      updateLocalStorage("customer_club",clubCode.join(' , '),event.id, ticket.id,count+1);
      updateLocalStorage("customer_birthplace",birthPlace.join(' , '),event.id, ticket.id,count+1);
      updateLocalStorage("customer_birth",pbirthDates.join(' , '),event.id, ticket.id,count+1);
      updateLocalStorage("customer_passport",passportNo.join(' , '),event.id, ticket.id,count+1);
      updateLocalStorage("customer_expiration",expiration.join(' , '),event.id, ticket.id,count+1);
      updateLocalStorage("customer_nationality",nationality.join(' , '),event.id, ticket.id,count+1);
      updateLocalStorage("customer_invoice",pinvoiceSelect.join(' , '),event.id, ticket.id,count+1);
      updateLocalStorage("customer_invoicedetail",pinvoiceDetails.join(' , '),event.id, ticket.id,count+1);
    }
  
  const Handler = ()=>{
    if (allRequiredFilled())      
    {
      setCustomerPopup(false)
      HandlerBut(data);
    }
    else
    {
      window.alert(t('fillallfields'))
    }
  }

  return (
    <>
         <Popup
        className='modal-lg modal-dialog-scrollable'
        openModal={customerPopup}
        closeModal={() => setCustomerPopup(false)}
        title={
          <>
            <FontAwesomeIcon icon={faTicketAlt} className='mr-1' />
            {t('guestuser')}
          </>
        }
        footer={
          <>
            <span className='close'></span>
            <span>
              {t('SendButton')}
            </span>

          </>
        }
        footerAction={Handler}
      >
        <>
        <div>
        <div className='row'>
        <div className='col-12 col-md-12'>
        <small className='form-text text-muted mb-3 text-align-left font-15'>
            <FontAwesomeIcon
              icon={faInfoCircle}
              className='text-primary mr-1'
            />
            {t('guestmessage')}
          </small>
          <p className='text-align-left font-16'><b>{t('guestalready')}</b>{existingUser}</p>
                          </div>
                          </div>
                          
                          <>
                          {
                            personData.map((data,index)=>(
                          <>
                          {
                            personData.length>1 && <>
                            <p className='text-align-left font-18'><b>{t('Person') + ' : '+ (index+1)}</b></p>
                            </>
                          }
                          <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              classNameLabel="set-flex"
                              label={t('PopupPage.FirstName')}
                              id={'firstName'+index}
                              name={'firstName'+index}
                              value={firstName[index]}
                              onChange={e => {
                               firstName[index]= e.target.value
                               setFirstName([...firstName])
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              classNameLabel="set-flex"
                              label={t('PopupPage.LastName')}
                              id={'lastName'+index}
                              name={'lastName'+index}
                              value={lastName[index]}
                              onChange={e => {
                               lastName[index] = e.target.value;
                               setLastName([...lastName])
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              classNameLabel="set-flex"
                              label={t('PopupPage.CustomerEmail')}
                              id={'customerEmail'+index}
                              name={'customerEmail'+index}
                              value={customerEmail[index]}
                              onChange={e => {
                                customerEmail[index]=e.target.value;
                                setCustomerEmail([...customerEmail])
                               }}
                              required
                            />
                          </div>
                        </div> 
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              classNameLabel="set-flex"
                              label={t('PopupPage.CustomerEmailConfirm')}
                              id={'customerEmailConfirm'+index}
                              name={'customerEmailConfirm'+index}
                              value={customerEmailConfirm[index]}
                              onChange={e => {
                                customerEmailConfirm[index]=e.target.value
                                setCustomerEmailConfirm([...customerEmailConfirm])
                               }}
                              required
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              classNameLabel="set-flex"
                              label={t('PopupPage.BirthPlace')}
                              id={'birthplace_id'+index}
                              name={'birthplace_id'+index}
                              value={birthPlace[index]}
                              onChange={e => {
                                birthPlace[index]=e.target.value;
                                setBirthPlace([...birthPlace])
                               }}
                              required
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              classNameLabel="set-flex"
                              label={t('PopupPage.PassportNo')}
                              id={'passportno_id'+index}
                              name={'passportno_id'+index}
                              value={passportNo[index]}
                              onChange={e => {
                                passportNo[index]=e.target.value;
                                setPassportNo([...passportNo])
                               }}
                              required
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              classNameLabel="set-flex"
                              label={t('PopupPage.Expiration')}
                              id={'expiration_id'+index}
                              name={'expiration_id'+index}
                              value={expiration[index]}
                              onChange={e => {
                                expiration[index]=e.target.value;
                                setExpiration([...expiration])
                               }}
                              required
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              classNameLabel="set-flex"
                              label={t('PopupPage.Nationality')}
                              id={'nationality_id'+index}
                              name={'nationality_id'+index}
                              value={nationality[index]}
                              onChange={e => {
                                nationality[index]=e.target.value;
                                setNationality([...nationality])
                               }}
                              required
                            />
                          </div>
                        </div>
                        <div className='row'>
                        <div className='col-12 col-md-12'>
                          <DateInput
                            classNameLabel="set-flex"
                            label={t('PopupPage.BirthDate')}
                            type='text'
                            required
                            id={'birth_date'+index}
                            setStartDate={date => {
                              birthDate[index]=date;
                              setBirthDate([...birthDate])
                            }}
                            startDate={birthDate[index]}
                          />
                        </div>
                        </div>                
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              classNameLabel="set-flex"
                              label={t('PopupPage.PhoneNumber')}
                              id={'phoneCode'+index}
                              name={'phoneCode'+index}
                              value={phoneCode[index]}
                              type='tel'
                              onChange={e => {
                                phoneCode[index]=e.target.value
                                setPhoneCode([...phoneCode])
                               }}
                              required
                            />
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-12 col-md-12'>
                          <CustomSelect
                           classNameLabel="set-flex"
                           className='custom_select-div set-flex-select'
                           required={true}
                           label={t('PopupPage.Invoice')}
                           options={[
                           {
                            label: t('Yes'),
                            value: 1
                          },
                          {
                           label: t('No'),
                           value: 0
                          }
                         ]}
                         name={'invoice'+index}
                         isClearable={false}
                         value={invoiceselect[index]}
                         handleChange={e => {
                          invoiceselect[index]=e;
                          SetInvoiceSelect([...invoiceselect])
                         }}
                           />
                          </div>
                        </div>
                        {
                        invoiceselect[index].value==1 && <>
                        <div className='row'>
                        <div className='col-12 col-md-12'>
                        <LabeledInput
                        classNameLabel="set-flex"
                        textArea
                        label={t('PopupPage.InvoiceDetail')}
                        type='text'
                        id={'invoice_detail'+index}
                        name={'invoice_detail'+index}
                        value={invoiceDetail[index]}
                        onChange={e => {
                          invoiceDetail[index]=e.target.value
                          setInvoiceDetail([...invoiceDetail])
                         }}
                        required
                      />
                      </div>
                      </div></>
                        }
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              classNameLabel="set-flex"
                              label={t('PopupPage.Club')}
                              id={'club_id'+index}
                              name={'club_id'+index}
                              value={clubCode[index]}
                              onChange={e => {
                                clubCode[index]=e.target.value;
                                setClubCode([...clubCode])
                               }}
                              required
                            />
                          </div>
                        </div>
                        </>
                            ))
                          }
                          </>                          
                        </div>
        </>
      </Popup>
    {maxtict <= 0 ? '' : 
      <div className={`${props.label ? 'form-group' : ''}`}>
        {props.label ? (
          <label className={`${props.required ? 'required' : ''}`}>
            {props.label} 
          </label>
        ) : (
          ''
        )}
        {props.info ? (
          <small className='form-text text-muted mb-3'>
            <FontAwesomeIcon
              icon={faInfoCircle}
              className='text-primary mr-1'
            />
            {props.info}
          </small>
        ) : (
          ''
        )}

        <div className='input-group bootstrap-touchspin bootstrap-touchspin-injected'>
          {props.isPrice ? (
            <span className='input-group-addon input-group-prepend bootstrap-touchspin-prefix'>
              <span className='input-group-text'>$</span>
            </span>
          ) : (
            ''
          )}
          <input
            type='text'
            className={`imp-white form-control touchspin-integer eventdate-ticket-qte ${
              props.bg_gray ? '' : 'bg-white'
            }`}
            value={count}
            onChange={e => changeHandler(e)}
          />
          <span className='input-group-btn-vertical'>
            {
              (ticket.supplements.length==0 || !allow) &&<><button
              className='btn btn-primary bootstrap-touchspin-up '
              type='button'
              style={props.isFrame?{backgroundColor:props.buttonColor,borderColor:props.buttonColor}:{}}
              onClick={() => clickHandler('add')}
            >
              +
            </button></>
            }
            {ticket.supplements.length!=0 && allow && 
              <>
               <Menu id={ticket.id+'-counter-popup'} menuButton={<MenuButton style={props.isFrame?{backgroundColor:props.buttonColor,borderColor:props.buttonColor}:{}} className='btn btn-primary bootstrap-touchspin-up '>
                <button
              className='remove-design'
              type='button'
              style={props.isFrame?{backgroundColor:props.buttonColor,borderColor:props.buttonColor}:{}}
            >
              +
            </button>
               </MenuButton>} transition>
                {
                 ticket.supplements.map(supplement => (
                  <>
                  {
                    supplement.supplement_type!=2 && (supplement.supplement_applied==1 || (supplement.supplement_applied==2 && count==0)) && <>
                      <div className='col-12 col-md-12' style={{display:'flex',alignItems:'center'}}>
                      {
                  supplement.supplement_type==1 && <>
                 <input
                  type='checkbox'
                  className={`checkboxmy check-${supplement.id}`}
                  id={supplement.id+','+ticket.id}
                  name={supplement.id+','+ticket.id}
                  placeholder={supplement.supplement_title}
                  style={{ marginRight: 5 }}
                />
                </>
                 }
                {
                  supplement.supplement_type==1 && <>
                  <label className={supplement.supplement_required==1?'required':''} htmlFor={supplement.id+','+ticket.id} style={{margin:'0px'}}>{supplement.supplement_title} €{supplement.supplement_price}</label>
                  </>
                }
                {
                  supplement.supplement_type==3 && <>
                    <div className='form-group'>
                   <label className={supplement.supplement_required==1?'required':''} htmlFor={supplement.id+','+ticket.id} style={{margin:'0px',float:'left',marginLeft:'5px',marginBottom:'5px'}}>{supplement.supplement_title}</label>
                   <input
                    className={`form-control text-input-mine checkboxmy check-${supplement.id}`}
                    id={supplement.id+','+ticket.id}
                    style={{ height: 30,marginTop:'8px' }}
                    type='text'
                    placeholder={supplement.supplement_title}
                   
                  />
                   </div>
                  </>
                }
                
              
              </div>
                    </>
                  }
                  {
                    supplement.supplement_type==2 && (supplement.supplement_applied==1 || (supplement.supplement_applied==2 && count==0)) && <>
                      <div>
                      {
                        supplement.supplement_multiple_type==1 && 
                        <>
                        <div className='col-12 col-md-12' style={{display:'flex',alignItems:'center'}}>
                        <RadioInput2
                      className={`custom-radios check-${supplement.id}`}
                            
                            label={supplement.supplement_title}
                            required={supplement.supplement_required==1?true:false}
                            info={''}
                            entries={
                              JSON.parse(supplement.supplement_option).map((option,index)=>{
                                return  {
                                  label: option.title +" €" + option.price,
                                  value: index.toString(),
                                  id: option.title+','+index+','+supplement.id+','+ticket.id,
                                  name:supplement.supplement_title
                                }
                              })
                            
                            }
                          
                          />
                          </div>
                        </>
                      }
                      {
                        supplement.supplement_multiple_type==0 && 
                        <>
                        <div>
                          
                       
                        <label className={`label-style ${supplement.supplement_required ? 'required' : ''}`}>
                          {supplement.supplement_title}
                        </label>
                        {
                  JSON.parse(supplement.supplement_option).map((option,index) => (
                  <>
                   <div className='col-12 col-md-12' style={{display:'flex',alignItems:'center'}}>
                   <input
                  type='checkbox'
                  className={`checkboxmy custom-checks check-${supplement.id}`}
                  id={option.title+','+index+','+supplement.id+','+ticket.id}
                  name={option.title+','+index+','+supplement.id+','+ticket.id}
                  placeholder={option.title}
                  style={{ marginRight: 5 }}
                  value={index.toString()}
                />
                  <label className={supplement.supplement_required==2?'required':''} htmlFor={option.title+','+index+','+supplement.id+','+ticket.id} style={{margin:'0px'}}>{option.title} €{option.price}</label>
                   </div>
                 
                </>
               
                 ))}
                   </div>
                        </>
                      }
                      
              </div>
                    </>
                  }
                  {
                    (supplement.supplement_applied==1 || (supplement.supplement_applied==2 && count==0)) &&
                    <>
                    <MenuDivider   />
                    </>
                  }
             
                  </>
                 
                ))}
              <MenuItem onClick={() => clickHandler('add')}><b style={{color:'#31c5f4'}}>{t('continue')}</b></MenuItem>
             </Menu>

        </>
            }
            
            <button
              className='btn btn-primary bootstrap-touchspin-down '
              type='button'
              style={props.isFrame?{backgroundColor:props.buttonColor,borderColor:props.buttonColor}:{}}
              onClick={() => clickHandler('sub')}
            >
              -
            </button>
          </span>
        </div>
      </div>
}
      {props.ticketLeftText !== false && ticket && ticket.quantity && show === 1 ? (
        <span className='badge badge-info mt-2'  style={props.isFrame?{backgroundColor:props.urlColor,borderColor:props.urlColor}:{}}>
          {(ticket.quantity - Number(ticket.booked_qty)) <= 0 ? '' : (ticket.quantity - Number(ticket.booked_qty))} tickets left
        </span>
      ) : ''}
    </>
  )
}
export default TicketCounter1
