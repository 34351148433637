import React, { useState, useEffect } from 'react';
import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from '../../sections/ui/formfields/Input';
import RadioInput from '../../sections/ui/formfields/RadioInput';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import countries from '../../sections/ui/countries'
import CustomSelect from '../../sections/ui/formfields/CustomSelect'

import {
  faEnvelope,
  faIdCard,
  faUser,
  faPhone,
  faUserLock,
  faKey,
  faUserTag
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Alert from '../../sections/ui/Alert';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { API_DOMAIN } from './../../Config';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useAlert } from 'react-alert';
import { useTranslation } from "react-i18next";
import RadioInput2 from '../../sections/ui/formfields/RadioInput2';
import { useSelector } from 'react-redux';

function Signup(props) {
  const {
    register,
    handleSubmit,
    formState: { errors }
    , reset,
    
  } = useForm();
  const { t, i18n } = useTranslation('common')
  const [RegisterData, setRegisterData] = useState([]);
  const [errorData, seterrorData] = useState([]);
  const [token, settoken] = useState('');
  const [gender, SetGender] = useState('Male');
  const [countryselect, SetCountrySelect] = useState({
    label: "Belgium",
    value: "BE"
  });
  const countriesData = useSelector(state => state.countries.countries);
  const [countryData, setCountryData] = useState([]);
  const [otpform, setOtpForm] = useState(false);

  const [marketing , SetMarketing] = useState(0);
  const [socialLoginId, setSocialLoginId] = useState({
    GoogleClientId: "",
    FacebookAppId: ""
  })
  const [isIphone, setIsIphone] = useState(false);
  //const [IsError, setIsError] = useState('');
  const onSubmit = formData => {
    seterrorData([]);
    checkUserAUthentication(formData);
  };
  const onChange = e => {
    SetGender(e.target.value);
  };

  useEffect(() => {
   
    if (countriesData && countriesData.length > 0) {
      let countriesArray = countriesData.map(country => ({
        label: country.country_name,
        value: country.country_code
      }));
      setCountryData(countriesArray);
    }
  }, [countriesData]);

  const alert = useAlert();

  
  const checkUserAUthentication = async formData => {
    var roleId = props.match.params.user === 'organizer' ? 3 : 2;
    if(!otpform)
    {
    var organisation =
      props.match.params.user === 'organizer' ? formData.organizername : '';
      var organisation_phone =
      props.match.params.user === 'organizer' ? formData.phone : '';
    if (formData.password === formData.confirmpassword) {
      try {
        await axios({
          url: API_DOMAIN + 'api/auth/new',
          method: 'POST',
          params: {
            first_name: formData.firstname,
            last_name: formData.lastname,
            role_id: roleId,
            email: formData.email,
            password: formData.password,
            password_confirmation: formData.confirmpassword,
            organisation: organisation,
            postal_code: formData.postal_code,
            gender: gender,
            marketing:marketing,
            phone:organisation_phone,
            country_code:countryselect.value
          }
        }).then(function (res) {
          if (res.code == 'auth_new_1') {
            seterrorData(res.data);
          } else {
            localStorage.setItem('userRes', JSON.stringify(res));
            setOtpForm(true);
            alert.success(t('MailSentSuccessfully'));
          }
        });
      } catch (error) {
        var info = t('PleaseCheckForm');
        seterrorData([info]);
        //seterrorData([]);
      }
    }
    else {
      var info = t('PasswordNotMatch');
      seterrorData([info]);
    }
  }
  else
  {
    let res = JSON.parse(localStorage.getItem('userRes'))
      try {
        await axios({
          url: API_DOMAIN + 'api/auth/verifyuser',
          method: 'POST',
          params: {
            otp_code:formData.otp_code,
            user_id:res.data.data.user_detail.id
          }
        }).then(function (rest) {
          if (res.code == 'auth_new_1') {
            alert.error(t('otpnotmatch'));
          }
          else
          {
            localStorage.removeItem('userRes')
            setRegisterData(res.data);
            if (roleId == 2) {
              alert.success(t('AccountCreatedSuccess'));
              setTimeout(function () {
              }, 10000);
              localStorage.setItem('user', JSON.stringify(res.data.data));
              localStorage.removeItem("customer_name");
              localStorage.removeItem("customer_phone");
              localStorage.removeItem("customer_email")
              localStorage.removeItem("customer_club");
              localStorage.removeItem("customer_birth");
              localStorage.removeItem("customer_birthplace");
              localStorage.removeItem("customer_invoice");
              localStorage.removeItem("customer_invoicedetail");
              localStorage.removeItem("customer_passport");
              localStorage.removeItem("customer_expiration");
              localStorage.removeItem("customer_nationality");
              let search = window.location.search;
              let params = new URLSearchParams(search);
              let redirect = params.get('redirect');
              if (redirect) {
                window.location.href = redirect;
              }
              else {
                window.location.href = '/dashboard/attendee/my-tickets';
              }
            } else {
              alert.success(t('AccountCreatedSuccess'));
              setTimeout(function () {
              }, 10000);
              localStorage.setItem('user', JSON.stringify(res.data.data));
              localStorage.removeItem("customer_name");
              localStorage.removeItem("customer_phone");
              localStorage.removeItem("customer_email")
              localStorage.removeItem("customer_club");
              localStorage.removeItem("customer_birth");
              localStorage.removeItem("customer_birthplace");
              localStorage.removeItem("customer_invoice");
              localStorage.removeItem("customer_invoicedetail");
              localStorage.removeItem("customer_passport");
              localStorage.removeItem("customer_expiration");
              localStorage.removeItem("customer_nationality");
              let search = window.location.search;
              let params = new URLSearchParams(search);
              let redirect = params.get('redirect');
              if (redirect) {
                window.location.href = redirect;
              }
              else {
                window.location.href = '/dashboard/organizer/';
              }
            }
          }
        });
      } catch (error) {
        alert.error(t('otpnotmatch'));
      }
    }
  
  };

  const resendOtp = async () =>{
    let res = JSON.parse(localStorage.getItem('userRes'))
    try {
      await axios({
        url: API_DOMAIN + 'api/auth/otpresend',
        method: 'POST',
        params: {
          user_id:res.data.data.user_detail.id
        }
      }).then(function (rest) {
        if (res.code == 'auth_new_1') {
          alert.error(t('PleaseCheckForm'));
        }
        else
        {
          alert.success(t('MailSentSuccessfully'));
        }
      });
    } catch (error) {
      alert.error(t('PleaseCheckForm'));
    }
  }

  
  const facebookLogin = async response => {
    const fbData = response;
    if (fbData.email != undefined) {
      var roleId = props.match.params.user === 'organizer' ? 3 : 2;
      const headers = {
        accept: 'application/json'
      };
      const signUpData = {
        email: fbData.email,
        first_name: fbData.name,
        last_name: '',
        role_id: roleId
      };
      try {
        await axios
          .post(API_DOMAIN + 'api/auth/social_login', signUpData, { headers })
          .then(response => {
            if (response.data.success) {
              localStorage.setItem('user', JSON.stringify(response.data.data));
              localStorage.removeItem("customer_name");
              localStorage.removeItem("customer_phone");
              localStorage.removeItem("customer_email")
              localStorage.removeItem("customer_club");
              localStorage.removeItem("customer_birth");
              localStorage.removeItem("customer_birthplace");
              localStorage.removeItem("customer_invoice");
              localStorage.removeItem("customer_invoicedetail");
              localStorage.removeItem("customer_passport");
              localStorage.removeItem("customer_expiration");
              localStorage.removeItem("customer_nationality");
              settoken(response.data.data.access_token);
              let search = window.location.search;
              let params = new URLSearchParams(search);
              let redirect = params.get('redirect');
              if (redirect) {
                window.location.href = redirect;
              }
              else if (response.data.data.user_detail.role_id == 2) {
                window.location.href = '/dashboard/attendee/my-tickets';
              } else {
                window.location.href = '/dashboard/organizer/';
              }
            }
          }).catch(function (error) {
            window.alert(error.response.data.message);
          });
      } catch (error) {
      }
    }
  };

  const responseGoogle = async response => {
    if (response.profileObj != undefined) {
      const profileObj = response.profileObj;
      var roleId = props.match.params.user === 'organizer' ? 3 : 2;
      const headers = {
        accept: 'application/json'
      };

      const signUpData = {
        email: profileObj.email,
        first_name: profileObj.givenName,
        last_name: profileObj.familyName,
        role_id: roleId
      };
      try {
        await axios
          .post(API_DOMAIN + 'api/auth/social_login', signUpData, { headers })
          .then(response => {
            if (response.data.success) {
              localStorage.setItem('user', JSON.stringify(response.data.data));
              localStorage.removeItem("customer_name");
              localStorage.removeItem("customer_phone");
              localStorage.removeItem("customer_email")
              localStorage.removeItem("customer_club");
              localStorage.removeItem("customer_birth");
              localStorage.removeItem("customer_birthplace");
              localStorage.removeItem("customer_invoice");
              localStorage.removeItem("customer_invoicedetail");
              localStorage.removeItem("customer_passport");
              localStorage.removeItem("customer_expiration");
              localStorage.removeItem("customer_nationality");
              settoken(response.data.data.access_token);
              let search = window.location.search;
              let params = new URLSearchParams(search);
              let redirect = params.get('redirect');
              if (redirect) {
                window.location.href = redirect;
              }
              else if (response.data.data.user_detail.role_id == 2) {
                window.location.href = '/dashboard/attendee/my-tickets';
              } else {
                window.location.href = '/dashboard/organizer/';
              }
            }
          }).catch(function (error) {
            window.alert(error.response.data.message);

          });
      } catch (error) {
      }
    }
  };

  const GetSocialLoginId = async () => {
    try {
      await axios({
        url: API_DOMAIN + 'api/settings',
        method: 'GET',
      }).then((response) => {
        const data = response.data.data;
        let FacebookAppId_Key = data.find(x => x.key === 'apps.facebook_app_id');
        let GoogleClientId_Key = data.find(x => x.key === 'apps.google_client_id');
        setSocialLoginId({
          GoogleClientId: GoogleClientId_Key.value,
          FacebookAppId: FacebookAppId_Key.value
        })

      })
    }
    catch {

    }
  }

  useEffect(() => {
    GetSocialLoginId();
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      setIsIphone(true)
    } 
  }, [])

  return (
    <section
      className='section-content padding-y bg-white'
      style={{ borderTop: '1px solid #f1f3f7' }}>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-5'>
            <h1 className='card-title text-center'>{t('Header.SignUp')}</h1>
          </div>
          <div className='col-xl-5 offset-xl-1 d-none d-xl-block'>
            <img
              src={` ${props.match.params.user === 'organizer'
                ? '/assets/uploads/register-organizer.c2449d93.png'
                : '/assets/uploads/register-attendee.29d4d837.png'
                }`}
              className='img-fluid'
            />
          </div>
          <form className='col-xl-4 col-12' onSubmit={handleSubmit(onSubmit)}>
            {!otpform && <>
              <div>
              <p>
                {
                   props.match.params.user !== 'organizer' ? (
                  socialLoginId.FacebookAppId != "" ?
                    <FacebookLogin
                      appId={socialLoginId.FacebookAppId}
                      callback={facebookLogin}
                      fields='name,email'
                      disableMobileRedirect={true}
                      isMobile={false}
                      render={renderProps => (
                        <button
                          onClick={renderProps.onClick}
                          className='btn btn-block btn-facebook'>
                          <FontAwesomeIcon icon={faFacebookF} /> &nbsp; {t('SignUpPage.FBLogin')}
                        </button>
                      )}
                    /> : <button

                      className='btn btn-block btn-facebook'>
                      <FontAwesomeIcon icon={faFacebookF} /> &nbsp; {t('SignUpPage.FBLogin')}
                    </button>
                   ):''
                }
                {
                  props.match.params.user !== 'organizer' ? (
                      socialLoginId.GoogleClientId != "" ?
                      <GoogleLogin
                        clientId={socialLoginId.GoogleClientId}
                        render={renderProps => (
                          <button
                            onClick={renderProps.onClick}
                            href='/connect/google'
                            className='btn btn-block btn-google-plus'>
                            <FontAwesomeIcon icon={faGoogle} /> &nbsp; {t('SignUpPage.GoogleLogin')}
                          </button>
                        )}
                        buttonText='Login'
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                      /> : <button
  
                        className='btn btn-block btn-google-plus'>
                        <FontAwesomeIcon icon={faGoogle} /> &nbsp; {t('SignUpPage.GoogleLogin')}
                      </button>
                  ):''
                }
         {
                (socialLoginId.GoogleClientId != "" && isIphone) && <>
                <p className="warning">{t('SignInPage.googlewarning')}</p> 
                </>
              }
             
              </p>
              {
                   props.match.params.user !== 'organizer' ? (
              <p className='divider-text'>
                <span>{t('SignUpPage.OR')}</span>
              </p>
                   ):''
            }

              {errorData.map((item, index) => (
                <div className='col-md-4 col-lg-12 px-2 mb-5 dealProduct2'>
                  <Alert type='danger' children={item}></Alert>
                </div>
              ))}

             

               {props.match.params.user === 'organizer' ? (
                <Input
                  type='text'
                  placeholder={t('SignUpPage.OrganizerName')}
                  name='organizername'
                  
                  icon={<FontAwesomeIcon icon={faIdCard} />}
                  register={register}
                />
              ) : (
                ''
              )}
              {props.match.params.user === 'organizer' ? (
                <Input
                  type='tel'
                  placeholder={t('OrganizerProfile.Phone')}
                  name='phone'
                  icon={<FontAwesomeIcon icon={faPhone} />}
                  register={register}
                />
              ) : (
                ''
              )}
              <Input
                type='text'
                placeholder={t('SignUpPage.FirstName')}
                name='firstname'
                icon={<FontAwesomeIcon icon={faUser} />}
                register={register}
              />
              <Input
                type='text'
                placeholder={t('SignUpPage.LastName')}
                name='lastname'
                icon={<FontAwesomeIcon icon={faUser} />}
                register={register}
              />
              {/* <Input
                type="text"
                placeholder="Username"
                name="username"
                icon={<FontAwesomeIcon icon={faUserTag} />}
                register={register}
              /> */}
              <Input
                type='email'
                placeholder={t('SignUpPage.EmailID')}
                name='email'
                icon={<FontAwesomeIcon icon={faEnvelope} />}
                register={register}
              />
              <Input
                type='password'
                placeholder={t('SignUpPage.Password')}
                name='password'
                icon={<FontAwesomeIcon icon={faUserLock} />}
                register={register}
              />
              <Input
                type='password'
                placeholder={t('SignUpPage.ConfirmPassword')}
                name='confirmpassword'
                icon={<FontAwesomeIcon icon={faUserLock} />}
                register={register}
              />   

             
              <CustomSelect
              className='custom_select-div'
              required={true}
              label={t('Country')}
              options={countryData}
              name='country'
              isClearable={false}
              value={countryselect}
              handleChange={e => {
               SetCountrySelect(e)
              }}
            />

              <Input
                type='text'
                placeholder= {t('PostalCode')}
                name='postal_code'
                icon={<FontAwesomeIcon icon={faUser} />}
                register={register}
              />
               
                <RadioInput2
                  label={t('SelectGender')}
                  info=''
                  entries={[
                    {
                      label: t('Male'),
                      value: 'Male',
                      id: 'enable_ticketLimitPurchase',
                      name: 'gender',
                      selected: gender === 'Male' ? true : false
                    },
                    {
                      label: t('Female'),
                      value: 'Female',
                      id: 'disable_ticketLimitPurchase',
                      name: 'gender',
                      selected: gender === 'Female' ? true : false
                    }
                  ]}
                  onChange={onChange}
                />
                 <div style={{display:'flex'}}>
                 <input
                  type='checkbox'
                  id='marketing'
                  name='marketing'
                  checked={marketing}
                  onChange={e =>
                    SetMarketing(e.target.checked ? 1 : 0)
                  }
                  placeholder='marketing'
                  style={{ marginRight: 5 }}
                />
                <label htmlFor='marketing' style={{marginTop:"-5px"}}>{t('marketing')}</label>
              </div>
              {/* <RadioInput
                type='radio'
                placeholder='First Name'
                name='firstname'
                entries={{"id":"1"}}
                label="Gender"
                register={register}
              /> */}
              <div className='mb-3 text-sm text-center'>
                <span className='text-muted'>
                  {t('SignUpPage.text1')}{' '}
                  <Link to='/TermsofServices'> {t('Footer.TermsOfServices')}</Link>,{' '}
                  <Link to='/PrivacyPolicy'>{t('Footer.PrivacyPolicy')}</Link> and{' '}
                  <Link to='/CookiePolicy'>{t('Footer.Cookiepolicy')}</Link>.
                </span>
              </div>
              <div className='form-group'>
                <button
                  type='submit'
                  name='_submit'
                  className='btn btn-primary btn-block'>
                  {t('SignUpPage.CreateAccount')}
                </button>
                {/* <a type="submit" className="btn btn-primary btn-block">
                  Create Account
              </a> */}
              </div>
              <p className='text-center'>
                {t('SignUpPage.AlreadyAMember')} <Link to='/signin'>{t('SignUpPage.SignInLink')}</Link>
              </p>
            </div>
            </>}
            {
              otpform && <>
              <div>
              {errorData.map((item, index) => (
                <div className='col-md-4 col-lg-12 px-2 mb-5 dealProduct2'>
                  <Alert type='danger' children={item}></Alert>
                </div>
              ))}
              <p className='otp-message'>{t('otpmessage')}</p>
              <Input
                type='text'
                placeholder= {t('otpenter')}
                name='otp_code'
                minLength={6}
                required
                maxLength={6}
                icon={<FontAwesomeIcon icon={faKey} />}
                register={register}
              />
               <div className='form-group'>
                <button
                  type='submit'
                  name='_submit'
                  className='btn btn-primary btn-block'>
                  {t('CategoryPage.Submit')}
                </button>
                <div className="py-3 d-flex align-items-center">
        <hr className="flex-grow-1" />
        <div className="badge">OR</div>
        <hr className="flex-grow-1" />
    </div>
                <button
                  type='button'
                  name='_otpsub'
                  onClick={() =>
                    {
                      resendOtp()
                    }}
                  className='btn btn-primary btn-block'>
                  {t('resendotp')}
                </button>
                {/* <a type="submit" className="btn btn-primary btn-block">
                  Create Account
              </a> */}
              </div>
              </div>
              </>
            }
            
          </form>
          
        </div>
      </div>
    </section>
  );
}
export default Signup;
