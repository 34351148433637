import Alert from '../../../sections/ui/Alert';
import React, { useEffect, useState } from 'react';
import Sidebar from '../../../sections/my-account/attendee/sidebar/Sidebar';
import Breadcrumbs from '../../../sections/ui/Breadcrumbs';
import Card from '../../../sections/ui/Card';
import CartItem from '../../../sections/my-account/attendee/cart/CartItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faCog,
  faMinusSquare
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { clearCart,removeFromCart } from '../../../service/actions/cartActions';
import { CART_ADD_ITEM_TO_STORAGE } from '../../../service/constants/cartConstants';
import { API_DOMAIN } from '../../../Config';
import axios from 'axios';
import { SETTINGS_API } from '../../../APIConfig';
import {useTranslation} from 'react-i18next';

function CartPage(props) {
  const {t,i18n} = useTranslation('common');

  const cartItems = useSelector(state => state.cart.cartItems);
  const dispatch = useDispatch();

  const [newCartItems, setNewCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [SettingData, setSettingData] = useState([]);

  useEffect(() => getSettingData(), []);

  const ClearAll = ()=>
  {
    localStorage.removeItem("customer_name");
    localStorage.removeItem("customer_phone");
    localStorage.removeItem("customer_email")
    localStorage.removeItem("customer_club");
    localStorage.removeItem("customer_birth");
    localStorage.removeItem("customer_birthplace");
    localStorage.removeItem("customer_invoice");
    localStorage.removeItem("customer_invoicedetail");
    localStorage.removeItem("customer_passport");
    localStorage.removeItem("customer_expiration");
    localStorage.removeItem("customer_nationality");
    dispatch(clearCart());
  }

  const getSettingData = async () => {
    try {
      await axios({
        url: SETTINGS_API,
        method: 'GET'
      }).then(function (res) {
        setSettingData(res.data.data);
      });
    } catch {}
  };

  useEffect(() => {
    const calculateCartData = async () => {
      const headers = {
        Accept: 'application/json'
      };
      const updatedCartItems = cartItems.map(item => ({
        ticket_id: item.ticket_id,
        event_id: item.event_id,
        ticket_qty: item.ticket_qty,
        coupon_id: '',
        supplements:item.ticket.selected_supplement?item.ticket.selected_supplement:{}
      }));
      const cart_detail = JSON.stringify(updatedCartItems);
      const { data } = await axios.post(
        `${API_DOMAIN}api/cart_detail`,
        { cart_detail },
        { headers }
      );
      const tData = data.data.ticket_detail.map(ticketItem => ({
        ticket: {
          id: ticketItem.ticket_id,
          title: ticketItem.ticket_title,
          totalTax: ticketItem.ticket_total_tax,
          quantity: ticketItem.ticket_qty,
          price: ticketItem.ticket_price,
          subTotal: ticketItem.ticket_subtotal,
          discountDetail: ticketItem.discount_detail,
          bookedQty: ticketItem.ticket_booked_qty,
          totalQty: ticketItem.ticket_total_qty,
          tickets: ticketItem.tickets,
          freeticket: ticketItem.free_ticket_get,
          selected_supplement:ticketItem.selected_supplement,
          supplements:ticketItem.supplements
        },
        event: ticketItem.event_details
      }));
     data.data.ticket_remove.forEach(res=>{
      dispatch(removeFromCart(res.event_id, res.ticket_id))
     }) 
      setNewCartItems(tData);
      setTotalPrice(data.data.total_price);
      setTotalFee(data.data.total_admin_org_fee)
    };
    calculateCartData();
  }, [cartItems]);
  let admin_org_fee_name = SettingData.find(o => o.key === 'site.admin_org_fee_name');

  return (
    <>
      <Breadcrumbs active='my-cart' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='my-cart' />
            <div className='col-lg-9'>
              <Alert type='warning'>
                {t('CartPage.Warning')}
              </Alert>
              <Card
                title={`${cartItems ? cartItems.length : 0
                  } ${t('CartPage.TicketCount')}`}>
                {!cartItems ||
                  (cartItems.length <= 0 && (
                    <div>
                      {t('CartPage.EmptyCartButton')}. <Link to='/event-list/All'>{t('ShopNow')}</Link>
                    </div>
                  ))}
                {cartItems && cartItems.length > 0 && (
                  <>
                    <div className='pb-3 px-3 pt-1'>
                      <div className='row no-gutters'>
                        <div className='col-7 col-md-7'>
                          <p className='text-muted font-weight-bold m-0'>
                            {t('CartPage.Event_Ticket')}
                          </p>
                        </div>
                        <div className='col-12 col-md-4 d-none d-md-block'>
                          <div className='d-flex justify-content-between'>
                            <p className='text-muted font-weight-bold m-0'>
                            {t('CartPage.Price')}
                            </p>
                            <p className='text-muted font-weight-bold m-0'>
                            {t('CartPage.Quantity')}
                            </p>
                            {/* <p className='text-muted font-weight-bold m-0'>
                              Tax
                            </p> */}
                            <p className='text-muted font-weight-bold m-0'>
                            {t('CartPage.Subtotal')}
                            </p>
                            <p className='text-muted font-weight-bold m-0 text-right'>
                              <FontAwesomeIcon icon={faCog} />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                    {newCartItems &&
                      newCartItems.length > 0 &&
                      newCartItems.map(cartItem => (
                        
                        <CartItem
                          title={cartItem.event.title}
                          link={`/event-details/${cartItem.event.slug}/${cartItem.event.id}`}
                          image={`/${cartItem.event.poster}`}
                          option={cartItem.ticket.title}
                          when={moment(
                            cartItem.event.start_date +
                            ' ' +
                            cartItem.event.start_time
                          ).format('dddd DD MMM YYYY HH:mm')}
                          where={`${cartItem.event.venue} ${cartItem.event.zipcode}, 
                            ${cartItem.event.city}`}
                          key={cartItem.event.id + '-' + cartItem.ticket.id}
                          price={+cartItem.ticket.price}
                          ticket={cartItem.ticket}
                          event={cartItem.event}
                          orgName={cartItem.event.user_detail.organisation}
                          selectedQuentity={cartItem.ticket.quantity}
                          item={cartItem.ticket}
                          itemD={cartItem}
                        />
                      ))}

                    <div className='cart_items-footer shopping-cart-wrap'>
                      <div className='price-wrap'>
                        <var className='price'>
                          {/* {admin_org_fee_name}: €{' '} */}
                          {t('CartPage.HandlingChanges')}: €{' '}
                          <span className='notranslate'>{totalFee.toString().replace('.', ',')}</span>
                        </var>
                      </div>
                      <div className='price-wrap'>
                        <var className='price'>
                        {t('CartPage.Total')}: €{' '}
                          <span className='notranslate'>{totalPrice.toString().replace('.', ',')}</span>
                        </var>
                      </div>
                    </div>

                    <div className='cart_items-footer shopping-cart-wrap'>
                      <span
                        className='btn btn-outline-primary mr-3 requires-confirmation'
                        onClick={() =>
                          window.confirm(
                            'Are you sure you want to clear cart?'
                          ) && ClearAll()
                        }>
                        <FontAwesomeIcon
                          icon={faMinusSquare}
                          className='mr-2'
                        />
                        {t('CartPage.EmptyCartButton')}
                      </span>
                      <Link
                        to='/event-list/All'
                        className='btn btn-primary primary1'
                        >
                        {t('CartPage.ContinueShoppingButton')}
                        {/* <FontAwesomeIcon
                          icon={faChevronRight}
                          className='ml-2'
                        /> */}
                      </Link>
                      <Link
                        to='/dashboard/attendee/checkout'
                        className='btn btn-primary'
                        onClick={() =>
                          dispatch({ type: CART_ADD_ITEM_TO_STORAGE })
                        }>
                        {t('CartPage.CheckoutButton')}
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className='ml-2'
                        />
                      </Link>
                    </div>
                  </>
                )}
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CartPage;
