import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../sections/ui/Breadcrumbs'
import Card from '../../sections/ui/Card'
import { clearCart } from '../../service/actions/cartActions'
// import { useHistory } from 'react-router-dom'
import {useTranslation} from "react-i18next";

const loggedInUser = localStorage.getItem('user')
const OrderSuccess = ({ match }) => {
  const dispatch = useDispatch()
  const orderId = match.params.orderNumber
  const {t, i18n} = useTranslation('common');
  console.log(orderId)
  useEffect(() => {
    dispatch(clearCart())
    localStorage.removeItem("customer_name");
    localStorage.removeItem("customer_phone");
    localStorage.removeItem("customer_email")
    localStorage.removeItem("customer_club");
    localStorage.removeItem("customer_birth");
    localStorage.removeItem("customer_birthplace");
    localStorage.removeItem("customer_invoice");
    localStorage.removeItem("customer_invoicedetail");
    localStorage.removeItem("customer_passport");
    localStorage.removeItem("customer_expiration");
    localStorage.removeItem("customer_nationality");
  }, [dispatch])

  return (
    <div>
      <Breadcrumbs active='' />
      <section className='section-content bg-white padding-y'>
        <div className='container'>
          <div className='row'>
            <main
              className='col-lg-12 order-1 order-lg-0 mt-4 mt-lg-0'
              style={{
                textAlign: 'center'
              }}
            >
              <Card>
                <h1>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color='green'
                    style={{ marginRight: 10 }}
                  />
                  {t('TicketBookedSuccessfully')}
                </h1>
                <br />
              </Card>
              <Card title='Details'>
                {orderId && <h3>{t('OrderNumber')}: #{orderId}</h3>}
                {
                  loggedInUser&&<>
                  <Link to='/dashboard/attendee/my-tickets'>{t('Header.MyTickets')}</Link>
                  </>
                }
              </Card>
              <a style={{fontSize:'14px',width:'fit-content'}} className="btn btn-primary"
          href={`https://www.simatours.myticketshop.be/backend/public/download/ticket/${orderId}/normal`}>
          {t('CartPage.DownloadTicket')}
        </a><br/><br/>
             
              <Link style={{fontSize:'18px'}} to='/event-list/All'>{t('CartPage.ContinueShoppingButton')}</Link>
            </main>
          </div>
        </div>
      </section>
    </div>
  )
}

export default OrderSuccess
